import headerList from "@/components/common/header-list.vue";
import paginationMixin from "@/Mixins/paginationMixin";
import PaginationComponent from "../../../Global-Component/pagination.vue";
import { _ } from "vue-underscore";
export default {
  data: () => ({
    cityGroupsList: [],
    searchText: "",
    showLoader: false,
    selected: "",
    selectedFile: [],
    selectedId: 0,
    selectedRows: [],
    sortColumn: "group_name",
    sortDirection: "asc",
    paginate: {
      page_count: 1,
      selected: 1,
      per_page: 50,
      current_page: 1,
      last_page: 0,
    },
    recordTakeOptions: [25, 50, 100, 200],
  }),
  mixins: [paginationMixin],
  components: { "header-list": headerList, PaginationComponent },
  computed: {
    selectAllList() {
      if (this.cityGroupsList.length > 0) {
        return (
          _.where(this.cityGroupsList, { isSelected: true }).length ===
          this.cityGroupsList.length
        );
      }
      return false;
    },
    hasCityGroupShowPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "List" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "List" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "List" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCityGroupAddPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Store" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Store" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Store" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCityGroupDeletePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Delete" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Delete" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Delete" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCityGroupUpdatePermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Update" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Update" }).length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Update" }).length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCityGroupImportPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "Import City Group" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "Import City Group" })
                .length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "Import City Group" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
    hasCityGroupExportPermission() {
      const menuList = this.$store.state.menuList;
      const currentRoute = this.$route.path;
      let hasPermission = false;
      menuList.forEach((item) => {
        if (
          item.menu_link === currentRoute &&
          _.where(item.permissions, { name: "export City Group" }).length > 0
        ) {
          hasPermission = true;
        } else if (item.child && item.child.length > 0) {
          item.child.forEach((element) => {
            if (
              element.menu_link === currentRoute &&
              _.where(element.permissions, { name: "export City Group" })
                .length > 0
            ) {
              hasPermission = true;
            } else if (element.child && element.child.length > 0) {
              element.child.forEach((subElement) => {
                if (
                  subElement.menu_link === currentRoute &&
                  _.where(subElement.permissions, { name: "export City Group" })
                    .length > 0
                ) {
                  hasPermission = true;
                }
              });
            }
          });
        }
      });
      return hasPermission;
    },
  },
  methods: {
    applySort(columnName) {
      // Check if the current sort column is the same as the clicked column
      if (this.sortColumn === columnName) {
        // If it is, toggle the sort direction between 'asc' and 'desc'
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        // If it's a different column, set the new sort column and direction to 'asc'
        this.sortColumn = columnName;
        this.sortDirection = "asc";
      }
      // Call the 'getAll' method to retrieve the sorted data
      this.getAll();
    },

    checkUncheckedList(event) {
      // Loop through the cityGroupsList and set the isSelected property to the value of event.target.checked
      this.cityGroupsList.forEach((element) => {
        element.isSelected = event.target.checked;
      });
    },

    deleteRecord(id) {
      let _vm = this;
      let ids = [];
      // Check if a single id is selected
      if (this.selectedId > 0) {
        ids = [this.selectedId];
      } else {
        // Iterate over the cityGroupsList to find selected elements
        this.cityGroupsList.forEach((element) => {
          if (element.isSelected == true) {
            ids.push(element.id);
          }
        });
      }
      _vm.axios
        .post("/city-groups-deletes", { ids: ids })
        .then(function (response) {
          _vm.getAll();
          _vm.selectedId = 0;
        })
        .catch(function () {});
    },

    search() {
      this.getAll();
    },
    getQueryString() {
      // Create the base query string with page, take, sort parameters
      let queryString =
        "?page=" +
        this.paginate.current_page +
        "&take=" +
        this.paginate.per_page +
        "&sort=" +
        this.sortColumn +
        "," +
        this.sortDirection;
      // Add search parameter if searchText is not empty
      queryString +=
        this.searchText.length > 0 ? "&search=" + this.searchText : "";
      return queryString; // Return the final query string
    },
    getNextPage(page) {
      this.paginate.current_page = page;
      this.getAll();
    },
    updateRecordTake(newRecordTake) {
      this.paginate.per_page = newRecordTake;
      this.getAll();
    },
    getPageCount(dividend, divider) {
      let page = Math.floor(dividend / divider);
      return dividend % divider === 0 ? page : page + 1;
    },

    getAll() {
      let _vm = this;
      let queryString = this.getQueryString();
      _vm.showLoader = true; // Show loader while making the request
      this.axios
        .get("/city-groups" + queryString)
        .then(function (response) {
          _vm.showLoader = false; // Hide loader after receiving the response
          let data = response.data.data.data;
          _vm.paginate.last_page = response.data.data.last_page;
          for (let index = 0; index < data.length; index++) {
            data[index]["isSelected"] = false; // Set isSelected to false for each data item
          }
          _vm.cityGroupsList = data; // Update the cityGroupsList with the received data
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total,
            response.data.data.per_page
          ); // Update the page count for pagination
        })
        .catch(function (error) {
          // Handle any errors that occurred during the request
        });
    },

    onFileChange(e) {
      // Retrieve the selected files from the event object
      var files = e.target.files || e.dataTransfer.files;
      // Check if any files were selected
      if (!files.length) {
        return;
      }
      // Clear the previously selected files
      this.selectedFile = [];
      // Iterate through each file and add it to the selectedFile array
      Array.prototype.forEach.call(files, (item) => {
        this.selectedFile.push(item);
      });
    },

    exportAirport() {
      // Retrieve city groups data for export
      this.axios.get("/export/city-groups").then((response) => {
        let a = document.createElement("a");
        a.href = response.data.data.file;
        a.download = response.data.data.name;
        a.click();
      });
    },
    uploadAirport() {
      // Check if a file is selected
      if (this.selectedFile && this.selectedFile.length > 0) {
        let _vm = this;
        let fd = new FormData();
        fd.append("xlsx", this.selectedFile[0]);
        // Send a POST request to import/city-groups endpoint
        this.axios.post("/import/city-groups", fd).then((response) => {
          _vm.selectedFile = [];
          document.getElementById("file-input").value = null;
          _vm.getAll();
        });
      } else {
        // Display an error message if no file is selected
        this.$toast.error("Please select a file first!");
      }
    },

    handleCheck(e) {
      // Check if the target element is customCheck-1
      if (e.target.id === "customCheck-1") {
        if (e.target.checked) {
          // Update selectedRows with checkbox IDs for all city groups
          this.selectedRows = this.cityGroupsList.map(
            (item) => "checkbox" + item.id
          );
          // Set isSelected property to true for all city groups
          this.cityGroupsList.forEach((element) => {
            element.isSelected = e.target.checked;
          });
        } else {
          // Reset selectedRows to an empty array
          this.selectedRows = [];
          // Set isSelected property to false for all city groups
          this.cityGroupsList.forEach((element) => {
            element.isSelected = false;
          });
        }
      } else {
        if (e.target.checked) {
          // Add the checked checkbox ID to selectedRows
          this.selectedRows.push(e.target.id);
        } else {
          // Remove the unchecked checkbox ID from selectedRows
          this.selectedRows = this.selectedRows.filter((item) => {
            if (item !== e.target.id) {
              return item;
            }
          });
        }
      }
    },
  },
  mounted() {
    this.getAll();
  },
};
